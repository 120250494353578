<template>
    <div style="margin-top: 75px;text-align: center;padding: 20px 140px;">
        <h1 style="margin-bottom: 50px;">“2024海南省文创大赛”参赛声明</h1>

        <div style="text-align: left; font-size: 20px;">依据国家有关法律法规，凡主动提交作品的“参赛者”或“作者”，即认为其已经对所提交的作品版权归属作如下不可撤销声明：</div>
        <div style="text-align: left; font-size: 20px;">
            1、参赛获奖作品相关知识产权归获奖者和大赛主办方共同所有，双方享有对作品共同进行知识产权保护申请、再设计、生产、展览、出版等各项权益；
        </div>
        <div style="text-align: left; font-size: 20px;">
            2、大赛组委会对所有参赛作品拥有展示、出版、宣传的权利。对于获奖作品，参赛者不能再次参加其他展览、比赛等活动，已在其他赛事中获奖的作品（产品）不得参赛，否则大赛组委会将追究其法律责任；
        </div>
        <div style="text-align: left; font-size: 20px;">
            3、参赛作品要求原创，如因抄袭或剽窃他人作品、窃取商业机密而发生产权或版权纠纷等，均由参赛者自行承担责任，对此大赛组委会将取消其参赛资格；

        </div>
        <div style="text-align: left; font-size: 20px;">
            4、参赛产品的邮寄费用由参赛者承担，因邮寄延误、错误、损坏、丢失等非主办方的原因影响参赛的，主办方不承担任何责任；

        </div>
        <div style="text-align: left; font-size: 20px;">
            5、参赛者提交作品之前，已仔细阅读上述条款，充分理解并表示同意。否则，组委会将有权取消其参赛与获奖资格，收回奖金、获奖证书和奖杯；

        </div>
        <div style="text-align: left; font-size: 20px;">
            6、本次大赛的评审委员会拥有对所有获奖作品的最终裁定权；

        </div>
        <div style="text-align: left; font-size: 20px;">
            7、如因违反上述权属而产生的一切法律纠纷，一切后果和损失由参赛者自行承担。
        </div>
    </div>
</template>

<script>

export default {
    name: "cultureOne",
    mounted() { },
    created() { },
    methods: {
    }
};
</script>
<style lang='less'></style>